<template>
  <div>
    <Return :item="item"/>
    <CardState :item="item" />
    <SaleAlert
      v-if="item!=null"
      :color="getColor({ state: item.order_status, shipping: item.shipping_line })"
      :state="item.order_status.name"
      :enablePersonalize="false"
      :item="item"
      @showPersonalization="dialog = true"></SaleAlert>
    <AppTable
      :dataTable="dataTable"
      :headers="headers"
      :getColor="getColor"
      :elevation="'elevation-0'"
      :expanded="expanded"
      @handleExpandedItem="handleExpandedItem"
    >
      <template v-slot:iconInsideColumn="slotProps">
        <component :is="setIcon(slotProps.item.id_sales_channel)" class="mr-4"/>
      </template>

      <template v-slot:columnExpandTable="slotProps">
        <ColumnExpand
          :item="slotProps.item"
          :isExpanded="slotProps.isExpanded"
          :expand="slotProps.expand"
        />
      </template>

      <template v-slot:expandedItemTable="slotProps">
        <ExpandedItem
          :item="slotProps.item"
          :headers="slotProps.headers"
          :expandHeaders="expandHeaders"
        />
      </template>
    </AppTable>
    <CenterCards :item="item"></CenterCards>
    <ViewCardDetails :boxInputValues="boxInputValues" :itemProp="item"></ViewCardDetails>
    <PopupPersonalization :dialog="dialog" :item="item" @closeDialog="dialog = false" :edit="false"></PopupPersonalization>
  </div>
</template>
<script>
import CardState from './CardState.vue'
import SaleAlert from './Alert.vue'
import CenterCards from './centerCards.vue'
import ViewCardDetails from './ViewCardDetail.vue'
import AppTable from '@/components/views/expandTable/AppTable.vue'
import ColumnExpand from '@/components/views/expandTable/tableComponents/ColumnExpand.vue'
import ExpandedItem from '@/components/views/expandTable/tableComponents/ExpandedItem.vue'
import stateDialog from './StateDialog.vue'
import Return from './Return.vue'
import PopupPersonalization from '@/components/personalize/PopupPersonalizacion.vue'
import { MeliIcon, WooIcon } from '@/assets/icons'
export default {
  created () {
    this.initialize()
  },
  components: { SaleAlert, AppTable, CenterCards, ViewCardDetails, ColumnExpand, ExpandedItem, MeliIcon, WooIcon, stateDialog, Return, PopupPersonalization, CardState },
  props: {
    item: Object
  },
  data: () => ({
    expanded: [],
    singleExpand: false,
    expandHeaders: [
      { text: 'producto', value: 'product_name', align: 'left' },
      { text: 'Nombre', value: 'name', align: 'left' },
      { text: 'Apellido', value: 'last_name', align: 'left' },
      { text: 'Personaje', value: 'icon', align: 'left' },
      { text: 'Color', value: 'color', align: 'left' },
      { text: 'Cantidad', value: 'quantity', align: 'center' },
      { text: 'Variacion', value: 'variants', align: 'left' },
      { text: 'comentario', value: 'commentary', align: 'left' },
      { text: 'Precio', value: 'unit_amount', align: 'center' },
      { text: 'Total', value: 'product_total', align: 'center' }
    ],
    headers: [
      { text: 'Numero', value: 'number' },
      { text: 'Correo', value: 'client.email' },
      { text: 'Fecha', value: 'created_at' },
      { text: 'Estado', value: 'state' },
      { text: 'Total', value: 'total_amount' },
      { text: '', value: 'data-table-expand' }
    ],
    dataTable: [],
    boxInputValues: [
      { text: 'Nombre', value: 'name' },
      { text: 'Apellido', value: 'last_name' },
      { text: 'Mail', value: 'email' }
      // { text: 'Teléfono', value: '' },
      // { text: 'Envio', value: 'client_address' }
      // { text: 'Dirección', value: 'client_address' },
      // { text: 'Código Postal', value: 'client_postcode' },
      // { text: 'Localidad', value: 'client_city' },
      // { text: 'Provincia', value: 'client_Province' }
    ],
    dialog: false
  }),
  methods: {
    initialize () {
      this.formatData()
      this.handleExpandedItem()
    },

    handleExpandedItem (val) {
      this.expanded = this.dataTable
    },

    formatData () {
      this.item.email = this.item.client.email
      this.item.products_order.forEach(v => {
        v.name = v.product.customizable ? v.name : 'no personalizable'
        v.last_name = v.product.customizable ? v.last_name : 'no personalizable'
        v.icon = v.product.customizable ? v.icon.replace(' ', '-').toLowerCase().trim() : 'no personalizable'
        v.color = v.product.customizable ? v.color : 'no personalizable'
        v.product_name = v.product.name
        v.product_icon = v.icon.name
        v.product_total = parseFloat(v.unit_amount) * parseFloat(v.quantity)
      })
      this.dataTable.push(this.item)
    },

    getColor (value) {
      let result = ''
      if (value.state.id === 5) {
        result = (value.shipping.includes('Retiro oficina') || value.shipping.includes('Retiro por local')) ? 'Pedido listo para retirar' : 'Pedido listo para despachar'
      } else {
        result = value.state.name
      }
      const colors = {
        Aprobado: 'rgba(232, 0, 237, 1)',
        'En produccion': 'rgba(255, 114, 0, 0.35)',
        'Pedido listo para retirar': 'rgba(0, 237, 137, 1)',
        'Pedido listo para despachar': 'rgba(0, 150, 137, 1)',
        'Pedido casi listo': 'rgba(142, 0, 237)',
        Cancelado: 'rgba(237, 71, 0, 1)'
      }
      return colors[result] || 'rgba(255, 114, 0, 0.35)'
    },

    setIcon (value) {
      const icon = {
        Meli: 'MeliIcon',
        Woo: 'WooIcon'
      }
      value = value === 2 ? 'Woo' : 'Meli'
      return icon[value] || 'div'
    }
  }
}
</script>
